import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Environment } from '@environments/environment';
import { LinkDirection } from '@private/pages/artifact-management/artifact/types/artifact.types';
import { AdvancedDateFilterObject } from '@shared/components/date-filter/types/date-filter.types';
import { FOLDER_FILTER_KEY, URL_KEY_VALUE_ARTIFACT_ID } from '@shared/constants/constants';
import { CoreListFilterEnum } from '@shared/core/types/core.types';
import { SortMainControlService } from '@shared/services/sort/sort-main-control.service';
import { NonAttributeKeys } from '@shared/types/attribute.types';
import {
  DateFilterEnum,
  DateRangeFilterEnum,
  ENUM_MATCH_MODE_OPTIONS,
  FolderFilterData,
  FolderFilterEnum,
  LinkFilterEnum,
  UserFilterEntry,
  UserFilterTypeEnum,
} from '@shared/types/filter.types';
import { SelectOption } from '@shared/types/shared.types';
import { SortAttributeObject, SortTypeEnum } from '@shared/types/sort.types';
import { FilterType, NewTableColumn, NewTableColumnMetaData } from '@shared/types/table.types';
import { NewSystemUser, TeamsWithUsers } from '@shared/types/user.types';
import { AttributeUtil } from '@shared/utils/attribute.util';
import { FilterMetadataUtil } from '@shared/utils/filter-metadata.util';
import { FilterUtil } from '@shared/utils/filter.util';
import { FilterMatchMode, FilterMetadata, SelectItem } from 'primeng/api';
import { MultiSelect } from 'primeng/multiselect';
import { ColumnFilter, SortIcon } from 'primeng/table';
import { EMPTY, merge, Subscription } from 'rxjs';
import { distinctUntilChanged, filter, map, tap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';
import { TableColumnControlService } from '../../services/table-column-control';
import { TableFilterUrlControlService } from '../../services/table-filter/table-filter-url-control.service';
import { ListWidgetOptions } from '../../types/list-widget-options.types';
import { LinkFilterNew } from '../../types/list-widget-selected.types';

@Component({
  selector: 'app-artifact-list-table-header-column',
  templateUrl: './artifact-list-table-header-column.component.html',
  styleUrls: ['artifact-list-table-header-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/* TODO: check usage of date and link filters and transform them into using of tableFilterControlService */
export class ArtifactListWidgetTableHeaderColumnComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() tableUniqueId: string;
  @Input() column: NewTableColumn;
  @Input() currentUser: NewSystemUser;
  @Input() lwOptions: ListWidgetOptions;
  @Input() sortableColumnKeys: string[];
  @Input() disabled: boolean;
  @Input() selectedLinkFilters: Record<LinkFilterEnum | string, LinkFilterNew>;
  @Input() selectedFolderFilters: Record<FolderFilterEnum, string>;
  @Input() selectedDateFilterObject: AdvancedDateFilterObject;
  @Input() teamsWithUsers: TeamsWithUsers;
  @Input() selectedUserFilter: UserFilterEntry;
  @Input() onLinkFilterApplyCb: (columnMetaData: NewTableColumnMetaData, filter: LinkFilterEnum | null, linkFilterUrlParamKey?: string) => Promise<void>;
  @Input() onLinkFilterClearCb: (columnMetaData: NewTableColumnMetaData, linkFilter: LinkFilterEnum | null) => Promise<void>;

  @ViewChild('columnFilterRef') columnFilterRef: ColumnFilter;
  @ViewChild('columnSortRef') columnSortRef: SortIcon;
  @ViewChild('textFilterRef') textFilterRef: TemplateRef<any>;
  @ViewChild('enumFilterRef') enumFilterRef: TemplateRef<any>;
  @ViewChild('enumFilterArtifactTypeRef') enumFilterArtifactTypeRef: TemplateRef<any>;
  @ViewChild('userFilterRef') userFilterRef: TemplateRef<any>;
  @ViewChild('dateFilterRef') dateFilterRef: TemplateRef<any>;
  @ViewChild('datetimeFilterRef') datetimeFilterRef: TemplateRef<any>;
  @ViewChild('booleanFilterRef') booleanFilterRef: TemplateRef<any>;
  @ViewChild('linkFilterRef') linkFilterRef: TemplateRef<any>;
  @ViewChild('folderFilterRef') folderFilterRef: TemplateRef<any>;
  columnFilterMetaData: ColumnFilterMetaDataItem | string = ColumnFilterMetaData.DEFAULT;
  filterTemplate: TemplateRef<any>;
  hasTemplate = true;
  hasActiveUrlFilter: boolean;
  hasActiveUrlSort: boolean;
  textFilterFocusOut = false;
  textFilterMatchModeChange = false;
  textFilterFocusInValue: string;
  dateFormat: string = Environment.calendarConfig.clientDateFormat;
  linkFilter: LinkFilterEnum | null = null;
  linkFilterUrlParamKey = URL_KEY_VALUE_ARTIFACT_ID;
  linkFilterEnumOptions = LinkFilterEnum;
  folderFilterData: FolderFilterData;
  userFilterEnumOptions: SelectOption<string, UserFilterTypeEnum>[];
  booleanFilterSelectOptions: SelectOption<string, string>[];
  userFilterTypeEnum = UserFilterTypeEnum;
  private initFilters: Record<string, any>;
  private initSubscription: Subscription;

  constructor(
    private readonly tableColumnControlService: TableColumnControlService,
    private readonly filterUrlControlService: TableFilterUrlControlService,
    private readonly filterMetaUtil: FilterMetadataUtil,
    private readonly sortMainControlService: SortMainControlService,
    private readonly attributeUtil: AttributeUtil,
    private readonly filterUtil: FilterUtil,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  @ViewChild('bfMultiSelect') set bfMultiSelect(bfMultiSelect: MultiSelect) {
    if (bfMultiSelect) {
      this.booleanFilterSelectOptions = this.filterUtil.getBooleanValueFilterSelectOptions(true);
    }
  }

  ngOnInit(): void {
    this.columnFilterMetaData = (this.column.meta.filterType && ColumnFilterMetaData[this.column.meta.filterType]) || ColumnFilterMetaData.DEFAULT;
    if (this.column.meta.filterType === FilterType.link && this.columnFilterMetaData && this.selectedLinkFilters) {
      this.initLinkFilters();
    }
  }

  ngAfterViewInit(): void {
    const urlAttributeLabel = this.getUrlQueryAttributeName();
    this.columnFilterRef && this.initColumnFilter();
    this.hasActiveUrlFilter = this.filterUrlControlService.hasActiveUrlParamFilter(this.tableUniqueId, urlAttributeLabel);
    this.initBasicFilterSettings();
    // syncing inner sort state with url sorts
    if (this.columnSortRef && this.sortMainControlService.hasActiveUrlChangeEventForOwnerAndAttribute(this.tableUniqueId, urlAttributeLabel)) {
      this.hasActiveUrlSort = true;
      this.doUpdateSortState();
    }

    // observers
    const filter$ = this.filterUrlControlService.getUrlQueryParams$().pipe(
      filter(tfupEvent => tfupEvent.ownerId === this.tableUniqueId && tfupEvent.urlFilteringOn && !!this.column.meta.filterUrlTypeService),
      map(tfupEvent => tfupEvent.urlQueryParams && tfupEvent.urlQueryParams[urlAttributeLabel]),
      filter(filterValue => filterValue || (!filterValue && this.hasActiveUrlFilter)),
      distinctUntilChanged(),
      tap(filterValue => this.doFilterOnUrlChange(filterValue)),
    );

    const sort$ = !this.columnSortRef
      ? EMPTY
      : this.sortMainControlService.getSortUpdate$().pipe(
          filter(
            sortUrlEvent =>
              sortUrlEvent.ownerId === this.tableUniqueId &&
              (sortUrlEvent.payload?.attributeName === urlAttributeLabel || (!sortUrlEvent.payload && this.hasActiveUrlSort)),
          ),
          distinctUntilChanged(),
          tap(sortUrlEvent => this.doSortOnUrlChange(sortUrlEvent.payload)),
        );

    const sortReset$ = this.sortMainControlService.getResetInternalSorts$().pipe(
      filter(
        ownerId =>
          ownerId === this.tableUniqueId && (this.hasActiveUrlSort || this.columnSortRef.sortOrder !== 0 || this.columnSortRef.field === this.column.key),
      ),
      tap(() => this.doUpdateSortState()),
    );

    this.initSubscription = merge(filter$, sort$, sortReset$).subscribe();
  }

  ngOnDestroy(): void {
    this.initSubscription && this.initSubscription.unsubscribe();
  }

  isOutgoing(): boolean {
    return this.column.meta.linkRestrictionParams?.direction === LinkDirection.outgoing;
  }

  isIncoming(): boolean {
    return this.column.meta.linkRestrictionParams?.direction === LinkDirection.incoming;
  }

  applyTextFilterCallbackOnFocusout(applyFilterFn: (value: string) => any, value: string): void {
    this.textFilterFocusOut = true;
    if (this.textFilterFocusInValue === value) {
      return;
    }
    applyFilterFn(value);
  }

  /**
   * Extending original hide functionality of primeng for accepting onPush.
   */
  initColumnFilter(): void {
    const originalHideFn = this.columnFilterRef.hide;
    this.columnFilterRef.hide = () => {
      this.cdr.markForCheck();
      originalHideFn.apply(this.columnFilterRef);
    };
  }

  /**
   * A kind of workaround for storing & invoking filter on apply button without necessity to call it twice.
   */
  initTextColumnFilter(): void {
    const originalApplyFilterFn = this.columnFilterRef.applyFilter;
    const originalOnMenuMatchModeChangeFn = this.columnFilterRef.onMenuMatchModeChange;

    this.columnFilterRef.applyFilter = () => {
      if (this.textFilterFocusOut && !this.textFilterMatchModeChange) {
        this.columnFilterRef.hide();
        this.textFilterFocusOut = false;
        return;
      }
      // reseting to default values
      this.textFilterMatchModeChange = false;
      this.textFilterFocusOut = false;
      // invoking original apply function
      originalApplyFilterFn.apply(this.columnFilterRef);
    };

    this.columnFilterRef.onMenuMatchModeChange = (value: any, filterMeta: FilterMetadata) => {
      this.textFilterMatchModeChange = true;
      originalOnMenuMatchModeChangeFn.apply(this.columnFilterRef, [value, filterMeta]);
    };
  }

  onLinkFilterApply(): void {
    this.onLinkFilterApplyCb && this.onLinkFilterApplyCb(this.column.meta, this.linkFilter, this.linkFilterUrlParamKey);
  }

  onLinkFilterClear(): void {
    this.onLinkFilterClearCb && this.onLinkFilterClearCb(this.column.meta, this.linkFilter);
    this.linkFilter = null;
  }

  onDateFilterApply(value: any, filterType?: DateRangeFilterEnum | DateFilterEnum): void {
    const filterMeta = this.columnFilterRef.fieldConstraints?.[0];

    if (filterMeta) {
      filterMeta.matchMode = filterType;
      filterMeta.value = value;
    }

    this.columnFilterRef.hide();
  }

  onDateFilterClear(): void {
    const filterMeta = this.columnFilterRef.fieldConstraints?.[0];
    filterMeta && (filterMeta.matchMode = undefined);
  }

  onDateMatchModeChange(filterType: string) {
    const filterMeta = this.columnFilterRef.fieldConstraints?.[0];
    filterMeta && (filterMeta.matchMode = filterType);
  }

  onFolderFilterDataUpdate(folderFilterData: FolderFilterData) {
    this.folderFilterData = folderFilterData;
  }

  onFolderFilterApply(folderFilterData: FolderFilterData): void {
    this.onFolderFilterDataUpdate(folderFilterData);
    const value = folderFilterData.includeSubfolders
      ? folderFilterData.folderPathForChildren
      : folderFilterData.selectedFolders?.map(folderNode => folderNode.data?.id);
    (this.columnFilterRef.dt.filters[this.columnFilterRef.field!] as FilterMetadata[])[0].matchMode = folderFilterData.includeSubfolders
      ? CoreListFilterEnum.startsWith
      : CoreListFilterEnum.in;
    (this.columnFilterRef.dt.filters[this.columnFilterRef.field!] as FilterMetadata[])[0].value = value;
    this.columnFilterRef.applyFilter();
    this.columnFilterRef.hide();
  }

  onFolderFilterClear(): void {
    this.onFolderFilterDataUpdate({});
    const filterValue = (this.columnFilterRef.dt.filters[this.columnFilterRef.field!] as FilterMetadata[])[0].value;
    if (filterValue) {
      this.columnFilterRef.clearFilter();
    }
    this.columnFilterRef.hide();
  }

  doOpenColumnMenu(event: Event): void {
    this.tableColumnControlService.doOpenTableColumnControl({ event, tableColumn: this.column, displayAtFormat: {} });
  }

  onArtifactTypeFilter(filter: any, values: string[]): void {
    // do invoke filtering for array of values
    filter(values);
  }

  onUserFilterTypeChange(filter: any): void {
    this.selectedUserFilter.value = undefined;
    filter(null);
  }

  onUserSelect(userIds: string[], filter: any): void {
    this.selectedUserFilter.value = userIds;
    filter(userIds);
  }

  onTeamSelect(teamIds: string[], filter: any): void {
    this.selectedUserFilter.value = [...teamIds];

    if (teamIds.includes(NonAttributeKeys.CURRENT_USER_TEAMS_ID)) {
      teamIds.splice(teamIds.indexOf(NonAttributeKeys.CURRENT_USER_TEAMS_ID), 1);
      this.currentUser.tenant.teamIds.forEach(teamId => !teamIds.includes(teamId) && teamId !== this.currentUser.tenant.everyoneTeamId && teamIds.push(teamId));
    }
    const users = this.teamsWithUsers.getUniqueUsersFromTeams(teamIds);
    const userIds = users.map(user => user.id);

    filter(userIds);
  }

  onUserFilterApply(): void {
    this.columnFilterRef.hide();
  }

  onUserFilterClear(filter: any): void {
    filter(null);
    this.selectedUserFilter.value = undefined;
  }

  /**
   * Inits basic filter settings.
   */
  private initBasicFilterSettings(): void {
    switch (this.column.meta.filterType) {
      case FilterType.text:
      case FilterType.numeric:
      case FilterType.counter:
      case FilterType.hyperlink:
        this.filterTemplate = this.textFilterRef;
        this.initTextColumnFilter();
        break;
      case FilterType.user:
        this.userFilterEnumOptions = Object.keys(UserFilterTypeEnum).map(
          key => new SelectOption(key, UserFilterTypeEnum[key as keyof typeof UserFilterTypeEnum]),
        );
        this.filterTemplate = this.userFilterRef;
        break;
      case FilterType.enum:
        this.filterTemplate = NonAttributeKeys.isArtifactTypeKey(this.column.key) ? this.enumFilterArtifactTypeRef : this.enumFilterRef;
        break;
      case FilterType.date:
        this.filterTemplate = this.dateFilterRef;
        this.customizeHasFilterFn();
        break;
      case FilterType.datetime:
        this.filterTemplate = this.datetimeFilterRef;
        this.customizeHasFilterFn();
        break;
      case FilterType.boolean:
        this.filterTemplate = this.booleanFilterRef;
        this.customizeBooleanHasFilterFn();
        break;
      case FilterType.link:
        this.filterTemplate = this.linkFilterRef;
        this.customizeHasFilterFn();
        break;
      case FilterType.folder:
        {
          if (this.hasActiveUrlFilter) {
            this.doFolderFilterOnUrlChange();
          } else {
            const filterMetadata = this.columnFilterRef.dt.filters[this.columnFilterRef.field!] as FilterMetadata[];
            if (filterMetadata?.length) {
              const folderFilterData = {
                folderPathForChildren: filterMetadata[0].value,
                includeSubfolders: filterMetadata[0].matchMode === CoreListFilterEnum.startsWith,
              };
              this.onFolderFilterDataUpdate(folderFilterData);
            }
          }
          this.filterTemplate = this.folderFilterRef;
          this.customizeHasFilterFn();
        }
        break;
      default:
        this.hasTemplate = false;
        break;
    }
  }

  private doFilterOnUrlChange(filterValue: any): void {
    const filterType = this.column.meta.filterType;
    this.hasActiveUrlFilter = !!filterValue;
    if (filterType === FilterType.date || filterType === FilterType.datetime) {
      this.doFilterOnDateUrlChange(filterValue);
    }
    if (filterType === FilterType.folder) {
      this.doFolderFilterOnUrlChange();
    }

    if (!this.initFilters) {
      this.initFilters = cloneDeep(this.columnFilterRef.dt.filters);
    }

    if (!filterValue) {
      filterValue = this.initFilters[this.column.key][0]?.value;
      this.columnFilterRef.dt.filters[this.column.key] = this.initFilters[this.column.key];
    }

    this.column.meta.filterUrlTypeService.doApplyFilter(this.column, filterValue, this.columnFilterRef);
  }

  private doFolderFilterOnUrlChange() {
    const folderFilterData: FolderFilterData = { externalFilterApplied: true };
    this.onFolderFilterDataUpdate(folderFilterData);
  }

  private doFilterOnDateUrlChange(filterValue: string): void {
    if (!filterValue) {
      this.onDateFilterClear();
      this.selectedDateFilterObject.filters[0].filterType = undefined;
      this.selectedDateFilterObject.filters[0].value = undefined;
      return;
    }
    let dateFilterValue: any;
    const filterValueElements = filterValue.split(',');
    const filterType = filterValueElements[0] as DateRangeFilterEnum | DateFilterEnum;
    const isNumeric = this.filterMetaUtil.isFilterNumeric(filterType as DateRangeFilterEnum);

    if (filterValueElements.length > 1) {
      if (isNumeric) {
        dateFilterValue = +filterValueElements[1];
      } else {
        dateFilterValue = filterValueElements.length === 2 ? filterValueElements[1] : filterValueElements.slice(1).map(dateString => new Date(dateString));
      }
    }
    this.selectedDateFilterObject.filters[0].filterType = filterType;
    this.selectedDateFilterObject.filters[0].value = dateFilterValue;
    this.onDateFilterApply(dateFilterValue, filterType);
  }

  private doSortOnUrlChange(sortAttributeObject: SortAttributeObject | null): void {
    if (sortAttributeObject) {
      this.hasActiveUrlSort = true;
      this.columnSortRef.sortOrder = this.sortMainControlService.getSortTypeValue((sortAttributeObject.sortType as SortTypeEnum) || SortTypeEnum.ASC);
      this.columnSortRef.dt.sortOrder = this.columnSortRef.sortOrder;
      this.columnSortRef.dt.sortField = this.columnSortRef.field;
      this.columnSortRef.dt.sortSingle();
    } else {
      this.hasActiveUrlSort = false;
      this.columnSortRef.dt.clear();
    }
  }

  private doUpdateSortState(): void {
    this.columnSortRef.updateSortState();
  }

  private initLinkFilters(): void {
    const { linkRestrictionParams } = this.column.meta;
    Object.keys(this.selectedLinkFilters).forEach(linkFilterEnumKey => {
      if (this.selectedLinkFilters[linkFilterEnumKey].linkColumns[linkRestrictionParams?.linkTypeId || '']) {
        const filterData = this.selectedLinkFilters[linkFilterEnumKey].linkColumns[linkRestrictionParams?.linkTypeId || ''];
        if (filterData?.linkDirection === linkRestrictionParams?.direction) {
          filterData?.linkFilterUrlParamKey && (this.linkFilterUrlParamKey = filterData.linkFilterUrlParamKey);
          linkFilterEnumKey && (this.linkFilter = linkFilterEnumKey as LinkFilterEnum);
        }
      }
    });
  }

  private customizeHasFilterFn(): void {
    const filterType = this.column.meta.filterType;
    const originalHasFilterFn = this.columnFilterRef.hasFilter;
    this.columnFilterRef.hasFilter = () => {
      if (filterType === FilterType.link) {
        return !!this.linkFilter;
      }
      if ((filterType === FilterType.date || filterType === FilterType.datetime) && this.selectedDateFilterObject) {
        const { value, filterType } = this.selectedDateFilterObject.filters[0];
        return this.filterMetaUtil.isFilterValidAndActive(value, filterType);
      }
      return originalHasFilterFn.apply(this.columnFilterRef);
    };
  }

  private customizeBooleanHasFilterFn(): void {
    this.columnFilterRef.hasFilter = function () {
      const fieldFilter = this.dt ? this.dt.filters[this.field!] : undefined;
      const filterValues = Array.isArray(fieldFilter) ? fieldFilter[0].value : fieldFilter?.value || null;
      if (filterValues == null) {
        return false;
      }
      return filterValues.length > 0;
    };
  }

  private getUrlQueryAttributeName() {
    return this.column.meta.filterType === FilterType.folder ? FOLDER_FILTER_KEY.toLowerCase() : this.attributeUtil.getUrlQueryAttributeName(this.column.label);
  }
}

class ColumnFilterMetaData {
  static readonly DEFAULT: ColumnFilterMetaDataItem = ColumnFilterMetaData.fromValues(true, true, true, true);
  static readonly [FilterType.date] = ColumnFilterMetaData.fromValues(false, false, false, false);
  static readonly [FilterType.datetime] = ColumnFilterMetaData.date;
  static readonly [FilterType.counter] = ColumnFilterMetaData.DEFAULT;
  static readonly [FilterType.time] = ColumnFilterMetaData.DEFAULT;
  static readonly [FilterType.text] = ColumnFilterMetaData.fromValues(false, true, true, true, FilterMatchMode.CONTAINS);
  static readonly [FilterType.boolean] = ColumnFilterMetaData.fromValues(false, false, false, true, FilterMatchMode.IN);
  static readonly [FilterType.numeric] = ColumnFilterMetaData.fromValues(true, true, true, true, FilterMatchMode.CONTAINS);
  static readonly [FilterType.enum] = ColumnFilterMetaData.fromValues(false, true, false, true, FilterMatchMode.IN, ENUM_MATCH_MODE_OPTIONS);
  static readonly [FilterType.user] = ColumnFilterMetaData.fromValues(false, true, false, false, FilterMatchMode.IN, ENUM_MATCH_MODE_OPTIONS);
  static readonly [FilterType.hyperlink] = ColumnFilterMetaData.fromValues(true, true, true, true, FilterMatchMode.CONTAINS);
  static readonly [FilterType.link] = ColumnFilterMetaData.fromValues(false, false, false, false, FilterMatchMode.EQUALS);
  static readonly [FilterType.folder] = ColumnFilterMetaData.fromValues(false, false, false, false, FilterMatchMode.EQUALS);

  static fromValues(
    showAddButton?: boolean,
    showMatchModes?: boolean,
    showOperator?: boolean,
    showBottomButtons?: boolean,
    matchMode?: FilterMatchMode,
    matchModeOptions?: SelectItem[] | null,
  ): ColumnFilterMetaDataItem {
    return { showAddButton, showMatchModes, showOperator, showBottomButtons, matchMode, matchModeOptions };
  }
}

class ColumnFilterMetaDataItem {
  showAddButton?: boolean;
  showMatchModes?: boolean;
  showOperator?: boolean;
  showBottomButtons?: boolean;
  matchMode?: FilterMatchMode;
  matchModeOptions?: SelectItem[] | null = null;
}
